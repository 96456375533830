var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-speed-dial",
    {
      attrs: {
        top: "",
        right: "",
        absolute: "",
        direction: "bottom",
        transition: "slide-y-reverse-transition"
      }
    },
    [
      _c(
        "v-btn",
        {
          attrs: {
            slot: "activator",
            color: "blue darken-2",
            dark: "",
            fab: ""
          },
          slot: "activator"
        },
        [
          _c("flag", {
            staticClass: "mr-2",
            attrs: { iso: _vm._f("country")(_vm.$i18n.locale) }
          }),
          _vm._v("\n    " + _vm._s(_vm.currentLocaleText) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.locales, function(locale) {
        return _c(
          "v-btn",
          {
            key: locale.value,
            attrs: {
              color: locale.color,
              "data-cy": "test",
              dark: "",
              fab: ""
            },
            on: {
              click: function($event) {
                _vm.setLocale(locale.value)
              }
            }
          },
          [
            _c("flag", {
              staticClass: "mr-2",
              attrs: { iso: _vm._f("country")(locale.value) }
            }),
            _vm._v("\n    " + _vm._s(locale.text) + "\n  ")
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }